.section {
  padding-top: 70px;
  min-height: calc(100vh - 70px);
}

.header {
  background-color: var(--nav-link-active) !important;
  color: white;
  max-width: 100% !important;
  font-size: 18px;
  padding: 5px 0;
}

.item {
  background-color: transparent;
  max-width: 100% !important;
}

.btn,
.btn:hover,
.btn:active {
  display: block !important;
  margin: auto !important;
  background-color: var(--title-color) !important;
}

.loader-container {
  width: 100% !important;
  height: 100% !important;
}
.project {
  min-height: 50vh !important;
  background-color: var(--nav-link-active) !important;
}

.footer {
  background-color: var(--nav-link-active) !important;
}

.box {
  min-width: 180px;
  max-width: fit-content;
  height: 120px;
  border-radius: 10px;
  color: var(--nav-link-active);
}

.loader-container {
  width: 100% !important;
}

.network {
  font-size: 16px;
}

.link {
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.see {
  cursor: pointer;
  color: var(--nav-link-active);
}

.see:hover {
  text-decoration: underline;
}
