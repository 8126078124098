:root {
  --nav-link: #000;
  --nav-link-active: #0d3885;
  --white-color: #fff;
  --bg-header: #afafaf url(./assets/123.jpg);
  --title-color: #1976d2;
  --box-shadow-card: 1px 2px 6px rgb(0 0 0 / 10%);
}
h4,
h5 {
  color: #fff;
}
.border2 {
  border: 1px solid var(--title-color) !important;
  background: var(--title-color) !important;
}
.border2:hover {
  border: 1px solid var(--nav-link-active) !important;
  background: var(--nav-link-active) !important;
}
.border2 a {
  color: var(--white-color) !important;
}
.btn-outline-primary {
  color: var(--title-color) !important;
  border: 1px solid var(--title-color) !important;
}
body {
  margin: 0;
  font-family: poppins, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  width: fit-content;
}

button:focus,
button:active {
  box-shadow: none !important;
}

button:disabled {
  cursor: not-allowed !important;
  pointer-events: all !important;
}

*:focus::placeholder {
  opacity: 0;
}

input,
textarea {
  border-color: #373737 !important;
}
@media (max-width: 575.98px) {
  .zoomM {
    zoom: 0.89;
  }
}
@media (max-width: 767.98px) {
  .smH {
    height: 80px !important;
  }
  .disF {
    margin-top: 2rem;
  }
  .mx--5 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  header {
    zoom: 0.8;
  }
  .d-grid-al {
    display: grid;
    grid-template-columns: 100% !important;
  }
}

.nav-link {
  color: var(--nav-link) !important;
  font-weight: 500 !important;
}
.navbar-nav .nav-link.active,
.navbar-nav .show > .nav-link {
  color: var(--nav-link-active) !important;
}
.bgB {
  background: var(--title-color) !important;
  border: 1px solid var(--title-color) !important;
}
.font-weight-500 {
  font-weight: 500;
}

.bgTitle {
  background: var(--bg-header);
  padding-top: 60px !important;
  padding-bottom: 50px !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  text-align: center;
}
.btn-check:active + .btn,
.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:active,
.btn:hover {
  background: var(--title-color) !important;
  border-color: var(--title-color) !important;
  color: var(--white-color) !important;
}
.card,
.card-header:first-child,
.card-footer:last-child {
  background-color: var(--nav-link-active) !important;
  border-bottom: none !important;
  border-radius: 18px !important;
  border-top: none !important;
}
.card-header {
  border-bottom: none !important;
}
.cardS .card-header:first-child,
.cardS .card-footer:last-child {
  background-color: transparent !important;
  border-bottom: none !important;
  border-top: none !important;
}
.card span {
  font-size: 16px;
  color: #fff;
}
.cardText {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.cardTextC {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.timeS {
  background: #fff;
  color: #000 !important;
  padding: 5px;
  border-radius: 6px;
  min-width: 147px;
  text-align: center;
}
.fw-bold1 {
  font-weight: 500 !important;
}
.card a {
  text-decoration: none !important;
  color: #fff;
  font-weight: 600 !important;
  font-size: 14px !important;
}
.colorT {
  color: #0d3885 !important;
}
.cardS span {
  color: #000 !important;
  font-weight: 600;
}

.d-grid-al {
  display: grid;
  grid-template-columns: 40% 25%;
}
.cPrim {
  color: var(--nav-link-active);
}
.cardS {
  padding: 15px !important;
  box-shadow: -1.04px 7.891px 51px 0px rgb(4 30 129 / 14%) !important;
  position: relative;
  background-color: #ffffffbd !important;
  border-radius: 18px !important;
  min-height: 310px;
}
.cardS h2 {
  color: #000;
}
.cardS .colorT {
  color: var(--nav-link-active) !important;
  font-weight: 600;
}
.card {
  box-shadow: var(--box-shadow-card);
  padding: 10px 0;
}
.cardS img {
  position: absolute;
  width: 75px;
  top: -31px;
  right: 0;
  left: 37%;
}

.cardS p {
  color: #000 !important;
}

.textBC {
  color: var(--title-color);
}

.textBC {
  color: var(--title-color);
}
.fw-boldT {
  font-size: 16px !important;
  font-weight: 500;
  color: var(--nav-link-active);
}
.alignTc {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #fff;
  padding: 10px 0;
}
.card p,
.card h1 {
  font-size: 16px;
  color: #fff;
}
.shodow {
  max-height: 70px;
  border-radius: 6px;
  max-width: 150px;
  margin-left: 2px;
}
footer {
  background: #0d3885;
  color: #fff;
  min-height: 45px;
  margin-top: 0.75rem;
}
.currentA {
  top: 105% !important;
  right: calc(100% + -100px) !important;
  background-color: #fff !important;
  opacity: 1 !important;
  color: var(--nav-link-active) !important;
  filter: none !important;
  font-size: 12px !important;
}
.modal-footer {
  border-top: none !important;
  justify-content: center !important;
}
.modal-content {
  box-shadow: 0 15px 15px;
  padding: 15px 0;
}
.borderState {
  box-shadow: 0 0 10px;
}

/* Remove arrows from inputs type number */

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
a {
  text-decoration: none !important;
}

.section {
  min-height: calc(100vh - 70px);
  width: 100%;
}
.waletNft {
  border: 1px solid var(--nav-link-active);
  border-radius: 15px;
  font-size: 14px;
  font-weight: 500 !important;
  padding: 5px 10px;
}
.linkW {
  color: var(--nav-link-active) !important;
}
.memberM {
  background: var(--white-color);
}
.memberM:hover {
  zoom: 1.1;
}
.mx--5 {
  margin-right: 4.5rem;
  margin-left: 4.5rem;
}
.btn-check:focus + .btn,
.btn:focus {
  color: var(--bs-btn-hover-color) !important;
}
.active > .page-link,
.page-link.active {
  background-color: var(--nav-link-active) !important;
  border: 1px solid var(--nav-link-active) !important;
}

.cardS:has(.bronzeS) .h3 {
  font-weight: 600;
  font-size: 24px !important;
}
.cardS:has(.silverS) .h3 {
  font-weight: 600;
  font-size: 24px !important;
}
.cardS:has(.goldS) .h3 {
  font-weight: 600;
  font-size: 24px !important;
}
.cardS .card-body {
  text-align: center;
}
.cardS .colorT {
  font-size: 20px;
}
.card .card-body {
  color: #fff;
  text-align: center;
}
.cP p {
  margin-bottom: 0 !important;
}
.div1 {
  position: absolute;
  top: -11px;

  bottom: 10px;
  right: 0;
  left: 0;
}
.div2 {
  z-index: 44;
  display: flex;

  position: absolute;
  left: 19px;
  right: 0;
  top: 68%;
}
.div1 img {
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
}
.disF {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.disF span {
  font-size: 14px;
  color: #cecae3;
}
.bodyP {
  min-height: 350px;
}

@media (min-width: 1400px) {
  .xlH {
    height: 235px !important;
  }
}
.Tilt-inner:hover .blocDesc {
  z-index: 9999 !important;
  text-align: justify;
  padding: 0 40px;
}

.blocDesc {
  z-index: -1;
  position: absolute;
  background: #162b50f0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 14px;
  border: 1px solid #162b50f0;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #fff;
}
