.img-container {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
}
.img-container img {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
}
