.container {
  position: relative;
  cursor: pointer;
  text-decoration: none;
}

.tooltip {
  position: absolute;
  top: 50%;
  right: calc(100% + 5px);
  background-color: black;
  opacity: 0.6;
  overflow: hidden;
  color: white;
  filter: blur(0.5px);
  width: 180px;
  transition: 0.3s;
  width: 180px;
  padding: 0rem 0.5rem !important;
  height: 0;
  overflow-wrap: break-all;
  word-wrap: break-all;
  word-break: break-all;
}

.container:hover .tooltip {
  height: fit-content;
  padding: 0.5rem !important;
}
