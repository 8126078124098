.menu {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  transition: 0.3s;
  list-style-type: none;
  margin: 0;
  background-color: white;
  width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.shown {
  height: fit-content;
  padding: 0.5rem;
  width: 250px;
}

.hidden {
  height: 0 !important;
  overflow: hidden;
}
.menu li {
  padding: 0.25rem;
}

.menu li a:hover,
.active {
  color: var(--nav-link-active) !important;
}
