.header {
  z-index: 10000 !important ;
  /* max-height: 60px !important; */
}

.tooltip {
  z-index: 10001 !important;
}

.text {
  font-size: 12px;
}
.menu-btn {
  background-color: transparent;
  border-color: black;
  margin-left: 0.5rem;
  color: black;
}
.menu-btn:active .menu-btn:checked,
.menu-btn:active,
.menu-btn:hover {
  background: var(--nav-link-active) !important;
  border-color: var(--nav-link-active) !important;
  color: var(--white-color) !important;
}

.active {
  color: var(--nav-link-active) !important;
}

.logout {
  background-color: transparent !important ;
  border-color: var(--nav-link-active) !important;
  color: var(--nav-link-active);
  outline: none;
}
