.text-container {
  min-height: 30px;
  background-color: var(--nav-link-active);
  font-size: 0.75rem;
}

.text-container .btn {
  border: 1px white solid;
  padding: 0.125rem 0.3rem;
}

.text-container .btn:hover {
  background-color: white;
  border-color: var(--nav-link-active);
}

.text-container .btn {
  cursor: pointer;
}

.text-container .btn:hover a {
  color: var(--nav-link-active) !important;
}
